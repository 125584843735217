<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Ecommerce: "Loja Virtual",
      Orders: "Pedidos",
      "Payment Method": "Forma de Pagamento",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "Aguardando pagamento",
      "Payment confirmed": "Pagamento confirmado",

      Address: "Endereço",
      "Send exactly value to make payment":
        "Envie exatamente o valor para efetuar o pagamento",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",

      "To validate your transaction, paste the transaction hash here":
        "Para validar sua transação, cole aqui a hash da transação",
      Send: "Enviar",

      "This order is not yours.": "Este pedido não é seu.",
      "XFB payment is not available for this order.":
        "O pagamento com XFB não está disponível para este pedido.",
      "The informed transaction hash has already been used.":
        "A hash de transação informada já foi utilizada.",
      "Transaction hash could not be registered, try again.":
        "Não foi possível registrar a hahs da transação, tente novamente.",

      "Click here to pay": "Clique aqui para pagar",
    },
    es: {
      Ecommerce: "Loja Virtual",
      Orders: "Pedidos",
      "Payment Method": "Forma de Pag",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "En espera de pago",
      "Payment confirmed": "Pago confirmado",

      Address: "Dirección",
      "Send exactly value to make payment":
        "Enviar exactamente el valor de hacer el pago",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",

      "To validate your transaction, paste the transaction hash here":
        "Para validar la transacción, pegar el hash transacción aquí",
      Send: "Enviar",

      "This order is not yours.": "Este pedido no es tuyo.",
      "XFB payment is not available for this order.":
        "XFB pago no está disponible para este fin.",
      "The informed transaction hash has already been used.":
        "El hash transacción informada ya se ha utilizado.",
      "Transaction hash could not be registered, try again.":
        "El hash de la transacción no se pudo registrar, intente nuevamente.",

      "Click here to pay": "Haga clic aquí para pagar",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      alert: {
        order: { type: "", message: "" },
        approve: { type: "", message: "" },
      },

      order: {
        id: this.$route.params.id,
        date: null,

        subtotal: "0.00",
        discount: "0.00",
        shipping: {
          method: "",
          value: "0.00",
          address: {
            zipcode: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            city: "",
            state: "",
          },
        },
        payment: {
          methods: [],
          list: [],
        },

        total: "0.00",
        topay: "0.00",

        items: null,
        status: null,

        approve: "done",
      },
    };
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get("franchise/orders/" + this.order.id)
          .then((response) => {
            if (response.data.status == "success") {
              this.order = response.data.order;
              this.loading = false;
            } else {
              this.empty = true;
              this.message = response.data.message;
            }
          })
          .catch((error) => {
            this.errored = error;
          });
      }
    },
    approveOrder() {
      api
        .post("franchise/orders", {
          id: this.order.id,
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.alert.order.type = "alert-success";
            this.alert.order.message = response.data.message;

            this.getOrder();
            this.$bvModal.hide("approveOrder");
          } else {
            this.alert.approve.type = "alert-danger";
            this.alert.approve.message = response.data.message;
          }
        });
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">
            {{ t("Ecommerce") }} › {{ t("Orders") }}
          </h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <div
              v-if="alert.order.message"
              :class="'alert ' + alert.order.type"
            >
              {{ t(alert.order.message) }}
            </div>
            <div v-if="errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="empty">{{ t("No records found.") }}</div>
            <div v-else class="p-2">
              <div class="d-flex">
                <h4 class="mb-0 font-size-15 flex-fill">#{{ order.id }}</h4>
                <h4 class="mb-0 font-size-15 flex-fill text-right">
                  {{ order.date }}
                </h4>
              </div>
              <hr />
              <div v-if="order.user" class="user d-flex mb-4">
                <div class="align-self-center mr-3">
                  <img
                    v-if="order.user.avatar"
                    class="rounded-circle header-profile-user"
                    :src="order.user.avatar"
                    alt=""
                    style="width: 60px; height: 60px;"
                  />
                  <img
                    v-else
                    class="rounded-circle header-profile-user"
                    :src="
                      'https://ui-avatars.com/api/?background=E9711D&color=fff&name=' +
                      order.user.name
                    "
                    alt=""
                    style="width: 60px; height: 60px;"
                  />
                </div>
                <h5 class="mb-0 flex-fill align-self-center font-size-16">
                  <small>{{ order.user.username }}</small
                  ><br />
                  {{ order.user.name }}
                </h5>
              </div>
              <div class="table-responsive">
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th class="border-0"></th>
                      <th class="border-0 text-uppercase">{{ t("Item") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Price") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Qty") }}</th>
                      <th class="border-0 text-uppercase text-right">
                        {{ t("Total") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td, index) in order.items" :key="index">
                      <td class="p-0" style="width: 1%">
                        <img
                          v-if="td.image"
                          style="width: 50px; height: 50px; object-fit: cover"
                          class=""
                          :src="td.image"
                        />
                      </td>
                      <td class="align-middle">{{ td.name }}</td>
                      <td class="align-middle">{{ td.price | currency }}</td>
                      <td class="align-middle">{{ td.quantity }}</td>
                      <td class="align-middle text-right">
                        {{ td.subtotal | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-2">{{ t("Status") }}</h3>
                  <div v-for="(td, index) in order.status" :key="index">
                    {{ td.date }} — {{ t(td.status) }}
                  </div>
                </div>
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-3">
                    {{ t("Detalhes do pagamento") }}
                  </h3>
                  <div class="table-responsive">
                    <table class="table table-nowrap table-borderless">
                      <tbody>
                        <tr v-if="order.discount != '0.00'">
                          <td class="px-0 py-1">{{ t("DISCOUNT") }}</td>
                          <td class="px-0 py-1 text-right">
                            - {{ order.discount | currency }}
                          </td>
                        </tr>
                        <tr v-if="order.subtotal != order.total">
                          <td class="px-0 py-1">{{ t("SUBTOTAL") }}</td>
                          <td class="px-0 py-1 text-right">
                            {{ order.subtotal | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1 font-weight-bold">
                            {{ t("TOTAL") }}
                          </td>
                          <td class="px-0 py-1 font-weight-bold text-right">
                            {{ order.total | currency }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="order.approve == 'pending'" class="my-3 text-center">
                <button
                  class="btn btn-default btn-lg"
                  @click="$bvModal.show('approveOrder')"
                >
                  Aprovar Pedido
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="approveOrder" hide-footer centered>
      <template v-slot:modal-title>Pedido no. {{ order.id }}</template>
      <div v-if="alert.approve.message" :class="'alert ' + alert.approve.type">
        {{ t(alert.approve.message) }}
      </div>
      <b-form class="p-3" @submit.prevent="approveOrder">
        <div v-if="order.user" class="d-block text-center">
          <h5>
            Desejá realmente aprovar o<br />
            pedido no. <strong>{{ order.id }}</strong> no valor de
            <strong>{{ order.total | currency }}</strong
            ><br />
            de <strong>{{ order.user.name }}</strong
            >?
          </h5>
        </div>
        <div class="d-flex flex-row pt-3">
          <b-button class="m-3" variant="default" type="submit" block
            >Sim</b-button
          >
          <b-button
            class="m-3"
            variant="danger"
            block
            @click="$bvModal.hide('approveOrder')"
            >Não</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </Layout>
</template>
